<template>
	<div class="main_header">
		<div class="main_slider" style="background-image:url(/images/main.jpg)">
			<div class="container fill_height">
				<div class="row align-items-center fill_height">
					<div class="col">
						<div class="main_slider_content">
							<h6>大阪ダーツオーガニゼイション</h6>
							<h1>
								<span class="accent">OSAKA</span>&nbsp;DARTS
								<br />ORGANIZATION
							</h1>
						</div>
					</div>
					<!--<div class="col text-right">
						<img src="images/prize/2020/kyoto2020.png" class="img-responsive" />
					</div>-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MainHeader"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
	font-size: 4rem;
	color: #fff;
	.accent {
		color: $primary-color;
	}
	@include sp {
		font-size: 2rem;
	}
}
h6 {
	font-size: 1rem;
	color: #fff;
	@include sp {
		font-size: 0.5rem;
		margin-bottom: 0.2rem;
	}
}

.main_slider {
	height: 400px;
	@include tab {
		height: 200px;
	}
}
.main_slider_content {
	text-align: left;
}
</style>
