<template>
	<div class="home">
		<MainHeader />

		<div class="information" id="information">
			<div class="container">
				<div class="row">
					<div class="col text-center">
						<div class="section_title">
							<h2>Information</h2>
						</div>
					</div>
				</div>

				<div class="row blogs_container">
					<div class="col-lg-4 mb-3">
						<div class="blog_item">
							<div
								class="blog_background"
								style="
									background-image: url(images/prize/2024/20240825_hyogo.jpg);
								"
							></div>
							<div
								class="blog_content d-flex flex-column align-items-center justify-content-center text-center"
							>
								<h4 class="blog_title">
									第33回 H.D.O.オープンダーツトーナメント大会
								</h4>
								<span class="blog_meta">2024.08.26 update</span>
								<a class="blog_more" href="/jdo/tournament2024/#20240825_hyogo"
									>ページを開く</a
								>
							</div>
						</div>
					</div>
					<div class="col-lg-4 mb-3">
						<div class="blog_item">
							<div
								class="blog_background"
								style="
									background-image: url(images/prize/2024/20240714_ehime.jpg);
								"
							></div>
							<div
								class="blog_content d-flex flex-column align-items-center justify-content-center text-center"
							>
								<h4 class="blog_title">OPEN DARTS TOUNAMENT IN EHIME 2024R6</h4>
								<span class="blog_meta">2024.07.16 update</span>
								<a class="blog_more" href="/jdo/tournament2024/#20240714_ehime"
									>ページを開く</a
								>
							</div>
						</div>
					</div>
					<div class="col-lg-4 mb-3">
						<div class="blog_item">
							<div
								class="blog_background"
								style="
									background-image: url(images/prize/2024/20240512_nara.jpg);
								"
							></div>
							<div
								class="blog_content d-flex flex-column align-items-center justify-content-center text-center"
							>
								<h4 class="blog_title">
									第4回 奈良ダーツオープントーナメント 2024
								</h4>
								<span class="blog_meta">2024.05.13 update</span>
								<a class="blog_more" href="/jdo/tournament2024/#20240512_nara"
									>ページを開く</a
								>
							</div>
						</div>
					</div>
					<!--<div class="col-lg-12">
						<div id="covid-19" class="covid-19-rules">
							<h3>
								<i class="fas fa-exclamation-circle"></i>
								2020年度のリーグ戦に関するお知らせ（2021.1.9更新）
							</h3>
							<div class="row">
								<div class="col-lg-12">
									<dl>
										<dt>
											<b>2021.1.9 再開延期のお知らせ</b>
										</dt>
										<dd>
											政府による緊急事態宣言に伴い、下記の通り
											リーグ開催の延期を決定致しましたのでお知らせいたします。<br />
											しかしながら、日々目まぐるしく状況が変わっておりますので、予定しております日程
											に変更が生じる場合もございます。<br />
											皆様の健康と安全を最優先に考えた上での決定ですので、何卒ご理解とご協力を賜りま
											すようお願い申し上げます。<br />
											今後につきましても通常とは違う形での運営になる可能性もありますが、世間の状況な
											どを鑑みた上で協議しながら進めて参りたいと思います。<br />
											皆様もどうぞご健康に留意してお過ごしくださいますようお願い申し上げます。
										</dd>
									</dl>
								</div>
							</div>
							<p>
								未経験のコロナ禍に全ベニュー、全会員の皆様が納得できる内容を決定していく事は到底困難であり課題は山積ではありますが、その中でもより良い方向へと向かうよう理事一同邁進して参ります、ODO会員の皆様におかれましては呉々もご自愛下さい。
							</p>
						</div>
					</div>-->
					<div class="col-lg-12">
						<div class="covid-19-guidelines">
							<h3>新型コロナウィルス感染拡大防止ガイドライン</h3>
							<div class="row">
								<div class="col-lg-6">
									<dl>
										<dt>公益財団法人日本スポーツ協会</dt>
										<dd>
											<a
												href="https://www.japan-sports.or.jp/about/tabid1278.html"
												target="_blank"
												>スポーツイベントの再開に向けた感染拡大予防ガイドライン</a
											>
										</dd>
										<dt>厚生労働省</dt>
										<dd>
											<a
												href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/0000164708_00001.html"
												target="_blank"
												>新型コロナウイルス感染症について</a
											>
										</dd>
									</dl>
								</div>
								<div class="col-lg-6">
									<dl>
										<dt>大阪府</dt>
										<dd>
											<a
												href="http://www.pref.osaka.lg.jp/kikaku/corona-kinkyuzitai/index.html"
												target="_blank"
												>【府民の皆様へのお願い】感染拡大防止に向けた取組み</a
											>
										</dd>
										<dt>スポーツ庁</dt>
										<dd>
											<a
												href="https://www.mext.go.jp/sports/b_menu/sports/mcatetop05/jsa_00010.html"
												target="_blank"
											>
												新型コロナウイルス感染対策
												スポーツ・運動の留意点と、運動事例について
											</a>
										</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="news">
				<div class="container">
					<div class="row">
						<div class="col text-center">
							<div class="section_title">
								<h2>News</h2>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12 col-md-8">
							<dl class="news__list row">
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2024.08.23</span>
								</dt>
								<dd class="col-10 col-md-10">
									2024年度中期リーグ戦の<a href="/team_ranking">対戦表</a>と<a
										href="/schedule"
										>ベニュー別スケジュール</a
									>を公開しました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2024.07.16</span>
								</dt>
								<dd class="col-10 col-md-10">
									<a href="/masters/2024/">ODOマスターズ2024の結果</a
									>を更新しました<span class="badge">NEW</span>
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2024.07.02</span>
								</dt>
								<dd class="col-10 col-md-10">
									ODOマスターズ2024の<a href="/masters/2024/"
										>エントリーリスト</a
									>を公開しました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2024.05.21</span>
								</dt>
								<dd class="col-10 col-md-10">
									改定版の<a href="/download" target="_blank"
										>競技規則・マナーブック</a
									>を公開しました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2024.05.13</span>
								</dt>
								<dd class="col-10 col-md-10">
									<a href="/annual_player_ranking" target="_blank"
										>2023年度 年間プレイヤーランキング</a
									>を公開しました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2024.05.13</span>
								</dt>
								<dd class="col-10 col-md-10">
									2024年度前期リーグ戦の<a href="/team_ranking">対戦表</a>と<a
										href="/schedule"
										>ベニュー別スケジュール</a
									>を公開しました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2023.03.27</span>
								</dt>
								<dd class="col-10 col-md-10">
									<a href="/members" target="_blank"
										>2024年度の会員リスト（受付完了分）</a
									>を公開しました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2023.03.06</span>
								</dt>
								<dd class="col-10 col-md-10">
									2024年度の<a
										href="/pdf/2024/member_entry_2024.pdf"
										target="_blank"
										>個人会員 登録申込書【継続】</a
									>を公開しました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2023.10.10</span>
								</dt>
								<dd class="col-10 col-md-10">
									<a href="/tournament/2023">ODO Darts Tournament 2023</a
									>の結果をアップしました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2023.09.12</span>
								</dt>
								<dd class="col-10 col-md-10">
									<a href="/tournament/2023/"
										>「ODO Darts Tournament 2023」の募集要項</a
									>を公開いたしました
								</dd>
								<!-- NEWS -->
								<dt class="col-2 col-md-2">
									<span class="date">2023.08.28</span>
								</dt>
								<dd class="col-10 col-md-10">
									2023年度中期リーグ戦の<a href="/team_ranking">対戦表</a>と<a
										href="/schedule"
										>ベニュー別スケジュール</a
									>を公開しました
								</dd>
							</dl>
						</div>
						<div class="col-12 col-md-4 news__list">
							<!--<a
								href="https://www.instagram.com/dartsbarleaflet/"
								target="_blank"
								><img src="images/event/20230416_leaflet.jpg" class="img-fluid"
							/></a>-->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="latest_league_data">
			<div class="container">
				<div class="row mb-5">
					<div class="col text-center">
						<div class="section_title">
							<h2>Latest League Data（2023）</h2>
						</div>
					</div>
				</div>
				<Dashboard />
			</div>
		</div>

		<div class="event_calendar">
			<div class="container">
				<div class="row mb-5">
					<div class="col text-center">
						<div class="section_title">
							<h2>Event Calendar</h2>
						</div>
					</div>
				</div>
				<div class="row mb-5">
					<!-- https://docs.zoho.com/sheet/publishrange.do?id=689b45873c2a3d8e6e7c3500c79561a0ffe14cb402b956017a268ecfe2679cfe -->
					<div class="col text-center">
						<iframe
							src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=NWJkYWtiNXNzb3VqbGw1NGY3cmNlazk0OGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NDRsMHJqYWlibjRhcmJvcDRvOW9iajYydThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%234285F4&amp;color=%23E4C441&amp;color=%23E67C73&amp;title=ODO%2FJDO%E3%82%AB%E3%83%AC%E3%83%B3%E3%83%80%E3%83%BC&amp;showTz=0"
							style="border-width: 0"
							width="1000"
							height="600"
							frameborder="0"
							scrolling="no"
						></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/common/MainHeader.vue";
import Dashboard from "@/components/common/Dashboard.vue";

export default {
	name: "home",
	components: {
		MainHeader,
		Dashboard,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
iframe {
	@include tab {
		width: 80vw;
		height: 80vw;
	}
}

.container {
	padding: 4rem 1rem 4rem 1rem;
	text-align: left;
}
.news,
.event_calendar {
	background-color: #f2f2f2;
	.news__list {
		margin: 2.5rem 0 1rem;
		@include sp {
			font-size: 80%;
		}
		dt {
			@include sp {
				padding: 0;
			}
		}
		dd {
			margin-bottom: 1.2rem;
		}
		.date {
			margin-right: 1rem;
			padding: 0.3rem;
			background-color: $primary-color;
			color: $primary-text-color;
		}
	}
}
.news__list {
	dd a {
		text-decoration: underline;
	}
}
span.badge {
	margin-left: 0.6rem;
	color: #000;
	font-size: 0.8rem;
	font-weight: normal;
	background-color: $secondary-color;
}
.covid-19-rules {
	background-color: #f0f4c3;
	margin-top: 2rem;
	padding: 2rem;
	h3 {
		text-align: center;
		margin-bottom: 1rem;
		font-size: 1.2rem;
	}
	dt {
		font-size: 1rem;
		padding: 0.4rem;
		margin-bottom: 0.6rem;
		border-bottom: 2px solid #000;
	}
	dd {
		margin-bottom: 1.5rem;
		margin-left: 1rem;
		a {
			text-decoration: underline;
		}
	}
	ul {
		list-style: square;
	}
	p {
		color: #333;
	}
	b {
		color: #f00;
	}
}
.covid-19-guidelines {
	background-color: #eee;
	padding: 2rem;
	h3 {
		text-align: center;
		margin-bottom: 1rem;
		font-size: 1.2rem;
	}
	dd {
		margin-bottom: 0.5rem;
		margin-left: 1rem;
		a {
			text-decoration: underline;
		}
	}
}
</style>
