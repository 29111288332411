<template>
	<div class="dashboard">
		<ul class="nav nav-pills" id="myTab" role="tablist">
			<li class="nav-item">
				<a
					class="nav-link active"
					data-toggle="tab"
					href="#a"
					@click="
						group_id = 1;
						getDashboard();
					"
				>
					<span class="group">A</span>ディビジョン
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					data-toggle="tab"
					href="#b"
					@click="
						group_id = 2;
						getDashboard();
					"
				>
					<span class="group">B</span>ディビジョン
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					data-toggle="tab"
					href="#c"
					@click="
						group_id = 3;
						getDashboard();
					"
				>
					<span class="group">C</span>ディビジョン
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					data-toggle="tab"
					href="#d"
					@click="
						group_id = 4;
						getDashboard();
					"
				>
					<span class="group">D</span>ディビジョン
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					data-toggle="tab"
					href="#e"
					@click="
						group_id = 5;
						getDashboard();
					"
				>
					<span class="group">E</span>ディビジョン
				</a>
			</li>
			<!--<li class="nav-item">
				<a
					class="nav-link"
					data-toggle="tab"
					href="#f"
					@click="
						group_id = 6;
						getDashboard();
					"
				>
					<span class="group">F</span>ディビジョン
				</a>
			</li>-->
		</ul>

		<div class="row mt-5">
			<div class="col-lg-6">
				<h3>
					Team Ranking
					<small>チームランキング</small>
				</h3>
				<div class="table-responsive">
					<table class="table table-striped">
						<thead class="thead-dark">
							<tr>
								<th scope="col">#</th>
								<th scope="col">Team</th>
								<th scope="col">Point</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="team in team_ranking">
								<tr :key="'team' + team.id">
									<th scope="row">{{ team.rank }}</th>
									<td>{{ team.name }}</td>
									<td class="pts">{{ team.pts }}</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>

				<h3 class="mt-4">
					Result
					<small>対戦結果</small>
				</h3>
				<div class="table-responsive">
					<table class="table table-striped">
						<template v-if="result.latest_date != null">
							<thead class="thead-dark">
								<tr>
									<th colspan="3">Result {{ result.latest_date }}</th>
								</tr>
							</thead>
							<tbody>
								<tr class="subtitle">
									<td>Win Team</td>
									<td></td>
									<td class="text-right">Lose Team</td>
								</tr>
								<template v-for="(result, index) in result.latest">
									<tr :key="'result' + index">
										<td scope="col-5" class="text-nowrap">
											<img
												:src="
													'images/teams/logo/' + result.win_team_code + '.jpg'
												"
												width="36px"
												class="rounded-circle mr-2"
											/>
											{{ result.win_team_name }}
											<i class="fas fa-chess-queen ml-2"></i>
										</td>
										<td scope="col-2" class="text-center text-nowrap">
											<span class="win mr-2">{{ result.win_team_count }}</span
											>-
											<span class="lose ml-2">{{
												result.lose_team_count
											}}</span>
										</td>
										<td scope="col-5" class="text-right text-nowrap">
											{{ result.lose_team_name }}
											<img
												:src="
													'images/teams/logo/' + result.lose_team_code + '.jpg'
												"
												width="36px"
												class="rounded-circle ml-2"
											/>
										</td>
									</tr>
								</template>
							</tbody>
						</template>
						<template v-if="result.next_date != null">
							<thead class="thead-dark">
								<tr>
									<th colspan="3">Next {{ result.next_date }}</th>
								</tr>
							</thead>
							<tbody>
								<tr class="subtitle">
									<td>Home Team</td>
									<td></td>
									<td class="text-right">Away Team</td>
								</tr>
								<template v-for="(result, index) in result.next">
									<tr :key="'result' + index">
										<td scope="col-5" class="text-nowrap">
											<img
												:src="
													'images/teams/logo/' + result.home_team_code + '.jpg'
												"
												width="36px"
												class="rounded-circle mr-2"
											/>
											{{ result.home_team_name }}
										</td>
										<td scope="col-2" class="text-center text-nowrap">
											<span class="badge badge-secondary">vs</span>
										</td>
										<td scope="col-5" class="text-right text-nowrap">
											{{ result.away_team_name }}
											<img
												:src="
													'images/teams/logo/' + result.away_team_code + '.jpg'
												"
												width="36px"
												class="rounded-circle ml-2"
											/>
										</td>
									</tr>
								</template>
							</tbody>
						</template>
					</table>
				</div>
			</div>
			<div class="col-lg-6">
				<h3>
					Average Ranking
					<small>アベレージランキング</small>
				</h3>
				<div class="table-responsive">
					<table class="table table-striped">
						<caption>
							※このランキングはAvgとTonを基準にしたデータに基づいています
						</caption>
						<thead class="thead-dark">
							<tr>
								<th scope="col">#</th>
								<th scope="col">Team</th>
								<th scope="col">Player</th>
								<th scope="col">Legs</th>
								<th scope="col">Avg</th>
								<th scope="col">Ton</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(player, index) in player_ranking">
								<tr :key="'rank' + index">
									<td>{{ player.rank }}</td>
									<td class="text-nowrap">{{ player.team_name }}</td>
									<td class="text-nowrap">{{ player.player_name }}</td>
									<td>{{ player.legs }}</td>
									<td class="text-nowrap">
										<span v-if="Math.floor(player.avg_darts) != 99">
											{{ player.avg_darts }}
										</span>
										<span v-else>--</span>
									</td>
									<td>{{ player.ton }}</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "Dashboard",
	data() {
		return {
			isLoading: true,
			team_ranking: [],
			result: [],
			player_ranking: [],
			group_id: null,
		};
	},
	mounted: function () {
		this.getDashboard();
	},
	methods: {
		getDashboard: function () {
			this.isLoading = true;
			/*let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666"
			});*/
			let params = {
				group_id: this.group_id,
			};
			this.axios
				.get("https://admin.darts-l.com/api/odo/dashboard", {
					params: params,
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data.result));
					this.team_ranking = res.data.team_ranking;
					this.result = res.data.result;
					this.player_ranking = res.data.player_ranking;
					// simulate AJAX
					setTimeout(() => {
						//loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
	width: 100%;
	caption {
		font-size: 0.8rem;
		caption-side: bottom;
	}
	.subtitle td {
		font-size: 0.8rem;
		padding: 0 0.5rem;
	}
}
.badge {
	font-size: 1.1rem;
}
.badge-finished {
	background-color: $primary-color;
}
.container {
	padding: 5rem 1rem;
	text-align: left;
}
.pts {
	font-size: 1.2rem;
}
.win,
.lose {
	font-size: 2rem;
}
.fa-chess-queen {
	color: $primary-color;
}
</style>
